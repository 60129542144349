import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, fonts, components }) => ({
  container: {
    margin: [0, spacing * 2],
    paddingBottom: components.curtain.height * 1.5,
  },
  title: {
    marginTop: 2 * spacing,
    paddingBottom: spacing,
    ...fonts.titleBold,
    '@media (max-width: 799px)': {
      ...fonts.titleBold['@media (max-width: 799px)'],
      padding: [spacing * 1.5, 0],
    },
  },
}));
