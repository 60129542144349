import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';
import useStyles from './styles';

const Press = ({ pageContext }) => {
  const { pageTitle, items } = pageContext;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{pageTitle}</h1>
      {items.map(({ id, ...rest }, index) => (
        <Button key={id} index={index} {...rest} />
      ))}
    </div>
  );
};

Press.propTypes = {
  pageContext: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string.isRequired).isRequired).isRequired,
  }).isRequired,
};

export default Press;
export { default as Head } from '@/components/Head';
