import React, { useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { LayoutContext } from '@/components/Layout';

import useStyles from './styles';

const Press = ({ route, date, title, author, index }) => {
  const { handleRedirect } = useContext(LayoutContext);
  const classes = useStyles(index);
  const parsedDate = useMemo(() => date.replace(/T.+$/, '').split('-').reverse().join('.'), [date]);

  const handleClick = useCallback(() => handleRedirect(route), [handleRedirect, route]);

  return (
    <button type="button" onClick={handleClick} className={classes.container}>
      <p className={classes.text1}>{parsedDate}</p>
      <p className={classes.text1}>{author}</p>
      <p className={classes.text2}>{title}</p>
    </button>
  );
};

Press.propTypes = {
  route: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default Press;
