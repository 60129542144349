import { createUseStyles } from 'react-jss';

export default createUseStyles(({ colors, spacing, fonts }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '120px 1fr',
    columnGap: 20,
    justifyItems: 'start',
    width: '100%',
    position: 'relative',
    top: (index) => -1 * index,
    padding: [0.5 * spacing, 0],
    margin: 0,
    borderWidth: [1, 0],
    borderStyle: 'solid',
    borderColor: colors.button.primary,
    outline: 'none',
    cursor: 'pointer',
    background: 'none',
    '@media (hover)': {
      '&:hover': {
        zIndex: 1,
        borderColor: colors.button.active,
        '&>p': {
          color: colors.button.active,
        },
      },
    },
    '@media (max-width: 799px)': {
      gridTemplateColumns: '1fr auto',
      justifyContent: 'space-between',
      padding: [1.5 * spacing, 0],
    },
    '@media (hover) and (max-width: 799px)': {
      '&:hover': {
        '&>p': {
          color: colors.button.active,
        },
      },
    },
  },
  text1: {
    ...fonts.subtitle,
    lineHeight: 1.75,
    color: colors.button.primary,
    '@media (max-width: 799px)': {
      ...fonts.subtitleSmall,
      color: colors.black,
    },
  },
  text2: {
    gridColumn: '2/3',
    ...fonts.title,
    color: colors.button.primary,
    fontWeight: 'noraml',
    textAlign: 'left',
    '@media (max-width: 799px)': {
      gridColumn: '1/3',
      ...fonts.subtitle,
      color: colors.button.primary,
    },
  },
}));
